<template>
  <div class="subscript__wrapper">
    <Loader :show="progress" />
    <ModalPay
      v-if="show_modal_pay"
      @close_modal="show_modal_pay = false"
      :tarif="tarif"
    />
    <div class="header__subscribe">
      <div class="header">
        <div class="balance__header">
          <span>Баланс</span>
          <span>{{ balance }}<i class="fa fa-rub" aria-hidden="true"></i></span>
          <span><button @click="show_modal_pay = true">Пополнить</button></span>
        </div>
        <div class="balance__info">
          <div class="info__item">
            <span>Расход в день</span>
            <span class="price"
              >{{ str_tarif }}<i class="fa fa-rub" aria-hidden="true"></i
            ></span>
          </div>
          <div class="info__item">
            <span>Дней оплачено</span>
            <span class="price" :style="{ color: func_get_color() }">{{
              str_day
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="body__subscribe">
      <FinanceList @load_end="progress = false" />
    </div>
  </div>
</template>

<script>
import FinanceList from "@/components/SettingUser/finance_list";
import Loader from "@/components/loader";
import ModalPay from "@/components/SettingUser/modal_pay";

export default {
  components: {
    FinanceList,
    Loader,
    ModalPay,
  },
  data() {
    return {
      balance: 0,
      tarif: 0,
      days: 0,
      progress: true,
      summ_pay: 0,
      show_modal_pay: false,
    };
  },
  methods: {
    func_pay() {
      if (Number(this.summ_pay) <= 0) return;
      let url = "order.php/payment";
      this.$sendRequest(url, {
          amount: this.summ_pay,
        })
        .then((result) => {
          if (!result) return;
          if (!result.link) return;
          window.open(result.link, "_blank");
        });
    },
    func_get_data() {
      this.progress = true;
      let url = "order.php/get_podpis_data";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.balance = result.balance;
        this.tarif = result.tarif;
        this.func_get_count_day();
      });
    },
    func_get_count_day() {
      this.days =
        Math.floor(this.balance / this.tarif) < 0
          ? 0
          : Math.floor(this.balance / this.tarif);
    },
    func_get_color() {
      if (this.days > 7) {
        return "#64b4d3";
      } else if (this.days <= 7 && this.days > 3) {
        return "#ff962a";
      } else {
        return "#d54949";
      }
    },
  },
  mounted() {
    this.func_get_data();
  },
  computed: {
    str_tarif: {
      get() {
        return this.tarif;
      },
      set(value) {
        this.tarif = value;
      },
    },
    str_day: {
      get() {
        return this.days;
      },
      set(value) {
        this.days = value;
      },
    },
  },
};
</script>

<style></style>
