<template>
  <div
    class="new__client__popup"
    :class="[$store.state.user_setting.blur ? 'blur' : 'no-blur']"
  >
    <div class="form__add_client__wrapper">
      <div class="header__back_btn">
        <div @click="func_close_add()" class="arrow-7">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="header__title">
        <h2>Добавить товар</h2>
      </div>
      <form @submit.prevent="func_save_product()" class="form__product">
        <div class="form__group">
          <label>Группа*</label>
          <div class="group__name">
            {{ group.value }}
          </div>
        </div>
        <div class="form__group">
          <label>Наименование*</label>
          <input type="tel" v-model="new_product.product" required />
        </div>
        <div class="form__group">
          <label>Артикул</label>
          <input type="text" v-model="new_product.article" />
        </div>
        <div class="form__group">
          <label>Цена*</label>
          <input type="number" v-model="new_product.price" required />
        </div>
        <div class="button__wrap">
          <button>Добавить</button>
        </div>
        <p class="msg" v-show="msg !== ''">{{ msg }}</p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    group: Object,
  },
  data() {
    return {
      msg: "",
      new_product: {
        product: "",
        article: "",
        price: "",
      },
    };
  },
  methods: {
    func_close_add() {
      this.$emit("close_product");
    },
    func_save_product() {
      let url = "order.php/add_product";
      this.$sendRequest(url, {
        ...this.new_product,
        group_id: this.group.id,
      }).then((result) => {
        if (!result) return;
        result.readonly = result.readonly === "true" ? true : false;
        this.$emit("add_product", result);
      });
    },
  },
  mounted() {},
};
</script>

<style></style>
