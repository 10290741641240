<template>
  <div class="devices__wrapper">
    <Loader :show="progress" />
    <div class="devices__header"></div>
    <div class="device__content">
      <div class="device__company">
        <form @submit.prevent="func_save_group">
          <div class="input__add">
            <input
              type="text"
              v-model="group"
              required
              placeholder="Добавить группу"
            />
            <button><i class="fa fa-plus" aria-hidden="true"></i></button>
          </div>
        </form>
        <div class="group__body">
          <div
            class="group__item"
            v-for="item in group_list"
            :key="item.id"
            :class="{
              selected: selected_group && selected_group.id === item.id,
            }"
            @click="func_select_group_item(item)"
          >
            <div class="title">{{ item.value }}</div>
            <div class="remove" @click="func_remove_group(item)">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="device__models" v-if="selected_group">
        <form @submit.prevent="func_save_device">
          <div class="input__add">
            <input
              type="text"
              v-model="device"
              required
              placeholder="Добавить устройство"
            />
            <button><i class="fa fa-plus" aria-hidden="true"></i></button>
          </div>
        </form>
        <div class="device__body">
          <div
            class="device__item"
            v-for="item in device_list.filter(
              (x) => x.group_id === selected_group.id
            )"
            :key="item.id"
          >
            <div class="title">{{ item.value }}</div>
            <div class="remove" @click="func_remove(item)">
              <i class="fa fa-times" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/loader";
export default {
  components: {
    Loader,
  },

  data() {
    return {
      device: "",
      group: "",
      device_list: [],
      group_list: [],
      selected_group: null,
      progress: true,
    };
  },
  methods: {
    func_save_device() {
      if (!this.device && this.device === "") return;
      if (!this.selected_group) return;
      let url = "order.php/add_device";
      this.$sendRequest(url, {
        value: this.device,
        group_id: this.selected_group.id,
      }).then((result) => {
        if (!result) return;
        this.device_list.push(result);
        this.device = "";
      });
    },
    func_select_group_item(obj) {
      this.selected_group = obj;
    },
    func_save_group() {
      if (!this.group && this.group === "") return;
      let url = "order.php/add_group";
      this.$sendRequest(url, { value: this.group }).then((result) => {
        if (!result) return;
        this.group_list.push(result);
        this.group = "";
      });
    },

    func_get_devices() {
      this.progress = true;
      let url = "order.php/get_device";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.device_list = result;
        this.progress = false;
      });
    },
    func_get_groups() {
      this.progress = true;
      let url = "order.php/get_groups";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.group_list = result;
        this.progress = false;
      });
    },
    func_remove(item) {
      if (!item) return;
      let url = "order.php/remove_device";
      this.$sendRequest(url, { id: item.id }).then(() => {
        this.device_list.splice(
          this.device_list.findIndex((x) => x.id === item.id),
          1
        );
      });
    },
    func_remove_group(item) {
      if (!item) return;
      let url = "order.php/remove_group";
      this.$sendRequest(url, { id: item.id }).then(() => {
        this.group_list.splice(
          this.group_list.findIndex((x) => x.id === item.id),
          1
        );
      });
    },
  },
  mounted() {
    this.func_get_devices();
    this.func_get_groups();
  },
};
</script>

<style></style>
