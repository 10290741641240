<template>
  <div class="main__wrapper">
    <div class="main__content__no__balance">
      <img :src="require('@/assets/catlow.png')" alt="" />
      <p>Вашего баланса не хватает для продления подписки</p>
      <button @click="show_setting_user = true">Пополнить</button>
      <transition name="customers">
        <SettingUser
          v-if="show_setting_user"
          @close_setting_user="show_setting_user = false"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import SettingUser from "@/components/_Pages/setting_user";
export default {
  components: {
    SettingUser,
  },
  data() {
    return {
      show_setting_user: false,
    };
  },
};
</script>

<style></style>
