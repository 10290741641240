<template>
  <div class="message__list">
    <div class="message">Сохранено</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style></style>
