<template>
  <div class="customer__edit__wrapper">
    <div class="close__btn" @click="$emit('close_customer_detail')">
      <i class="fa fa-external-link" aria-hidden="true"></i>
    </div>
    <Loader :show="progress" />
    <div class="customer__edit__body" v-if="customer">
      <table class="info">
        <tr>
          <td>ID клиента</td>
          <td>{{ customer.customer_num }}</td>
        </tr>
        <tr>
          <td>Имя</td>
          <td>
            <input
              type="text"
              v-model="customer.customer"
              @change="func_save_customer"
            />
          </td>
        </tr>
        <tr>
          <td>Телефон</td>
          <td>
            <input
              type="text"
              v-model="customer.phone"
              @change="func_save_customer"
            />
          </td>
        </tr>
        <tr>
          <td>Телефон 2</td>
          <td>
            <input
              type="text"
              v-model="customer.phone2"
              @change="func_save_customer"
            />
          </td>
        </tr>
        <tr>
          <td>Пометка</td>
          <td>
            <input
              type="text"
              v-model="customer.comment"
              @change="func_save_customer"
            />
          </td>
        </tr>
      </table>

      <table class="stars">
        <tr>
          <td>Адекватность</td>
          <td>
            <div class="review_stars_wrap">
              <i
                class="fa fa-star"
                :class="{ checked: Number(customer.rank) === 5 }"
                @click="func_edit_rank(5)"
              ></i>
              <i
                class="fa fa-star"
                :class="{ checked: Number(customer.rank) === 4 }"
                @click="func_edit_rank(4)"
              ></i>
              <i
                class="fa fa-star"
                :class="{ checked: Number(customer.rank) === 3 }"
                @click="func_edit_rank(3)"
              ></i>
              <i
                class="fa fa-star"
                :class="{ checked: Number(customer.rank) === 2 }"
                @click="func_edit_rank(2)"
              ></i>
              <i
                class="fa fa-star"
                :class="{ checked: Number(customer.rank) === 1 }"
                @click="func_edit_rank(1)"
              ></i>
            </div>
          </td>
        </tr>
      </table>
      <div class="customer__mark">
        <h5>Заметки</h5>
        <textarea
          v-model="customer.mark"
          @change="func_save_customer"
        ></textarea>
      </div>
      <button
        class="customer__detail_btn_add_order"
        @click="$emit('customer_add_order', customer)"
      >
        Добавить заказ
      </button>
      <div class="customer__orders">
        <h5>Заказы</h5>
        <table v-if="order_list.length > 0">
          <thead>
            <tr>
              <th>Заказ</th>
              <th>Создан</th>
              <th>Завершен</th>
              <th>Срок</th>
              <th></th>
              <th>{{ FIELDS_STATE.group_name }}</th>
              <th>{{ FIELDS_STATE.device }}</th>
              <th>{{ FIELDS_STATE.imei }}</th>
              <th>Cтоимость</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in order_list" :key="item.order_id">
              <td>{{ item.order_num }}</td>
              <td>
                {{ new Date(iosDate(item.date_create)).toLocaleDateString() }}
              </td>
              <td>
                {{ new Date(iosDate(item.date_end)).toLocaleDateString() }}
              </td>
              <td v-html="func_get_count_day_color(item)"></td>
              <td>
                <img
                  src="images/flame.png"
                  v-if="!!Number(item.fire)"
                  width="20"
                  height="20"
                  alt="lorem"
                />
              </td>
              <td>{{ item.group_name }}</td>
              <td>{{ item.device }}</td>
              <td>{{ item.imei }}</td>
              <td>{{ Number(item.price).toFixed(2) }}</td>
              <td>
                <button @click="func_link_order(item)">К заказу</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loader from "@/components/loader.vue";
export default {
  props: {
    customer_id: String,
  },
  components: {
    Loader,
  },
  data() {
    return {
      customer: null,
      order_list: [],
      progress: true,
    };
  },
  methods: {
    iosDate(date) {
      return date ? date.replace(" ", "T") : "";
    },
    func_edit_rank(rank) {
      this.customer.rank = rank;
      let url = "order.php/update_rank";
      this.$sendRequest(url, { customer_id: this.customer_id, rank: rank });
      this.$emit("update_customer", this.customer);
    },
    func_get_data() {
      this.progress = true;
      let param = {
        customer_id: this.customer_id,
      };
      let url = "order.php/get_customer";
      this.$sendRequest(url, param).then((result) => {
        if (!result) return;
        this.customer = result;
        this.progress = false;
      });
    },
    func_save_customer() {
      let url = "order.php/update_customer";
      this.$sendRequest(url, this.customer);
      this.$emit("update_customer", this.customer);
    },
    func_get_orders() {
      let url = "order.php/get_orders_customer";
      this.$sendRequest(url, { customer_id: this.customer_id }).then(
        (result) => {
          if (!result) return;
          this.order_list = result;
        }
      );
    },
    func_get_count_day_color(item) {
      if (item.status === "Завершен" || item.status === "Завершен неудачно")
        return "";
      let diffInDays = this.func_get_day(item);
      let color = "";

      if (diffInDays > 1) {
        color = "#328d30";
      } else if (diffInDays === 1) {
        color = "#dd8b37";
      } else {
        color = "#c74545";
      }

      return item.status === "Готов" ||
        item.status === "Выдан клиенту" ||
        item.status === "Возврат без ремонта"
        ? ""
        : "<span style='color:" +
            color +
            ";'>" +
            diffInDays.toString() +
            " д.</span>";
    },
    func_get_day(item) {
      let date_end = new Date(item.date_end);
      let date_now = new Date();

      let oneDay = 1000 * 60 * 60 * 24;
      let diffInTime = date_end.getTime() - date_now.getTime();
      let diffInDays = Math.round(diffInTime / oneDay);
      return diffInDays;
    },
    func_link_order(item) {
      this.$router.push({
        name: "main",
        params: { link_order_id: item.order_id },
      });
    },
  },
  mounted() {
    this.func_get_data();
    this.func_get_orders();
  },
  computed: {
    ...mapGetters(["FIELDS_STATE"]),
  },
};
</script>

<style></style>
