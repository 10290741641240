var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status__wrapper"},[_c('Loader',{attrs:{"show":_vm.progress}}),_vm._m(0),_c('div',{staticClass:"status__body"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.func_add_status.apply(null, arguments)}}},[_c('div',{staticClass:"input__add"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_status),expression:"new_status"}],attrs:{"type":"text","required":""},domProps:{"value":(_vm.new_status)},on:{"input":function($event){if($event.target.composing){ return; }_vm.new_status=$event.target.value}}}),_vm._m(1)])]),(_vm.status_list.length > 0)?_c('ul',{staticClass:"status__content"},_vm._l((_vm.status_list),function(item){return _c('li',{key:item.id},[(
            item.value !== 'Готов' &&
            item.value !== 'Завершен неудачно' &&
            item.value !== 'Завершен' &&
            item.value !== 'Принят'
          )?_c('div',{staticClass:"delete_status",on:{"click":function($event){return _vm.func_delete_status(item)}}},[_c('i',{staticClass:"fa fa-times",attrs:{"aria-hidden":"true"}})]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.value),expression:"item.value"}],attrs:{"type":"text","readonly":item.value == 'Готов' ||
            item.value == 'Завершен неудачно' ||
            item.value == 'Завершен' ||
            item.value == 'Принят'},domProps:{"value":(item.value)},on:{"change":function($event){return _vm.func_update_status(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "value", $event.target.value)}}}),_c('button',{style:({ background: item.color })},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.color),expression:"item.color"}],attrs:{"type":"color"},domProps:{"value":(item.color)},on:{"change":function($event){return _vm.func_update_status(item)},"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "color", $event.target.value)}}})])])}),0):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status__header"},[_c('h4',[_vm._v("Редактор статусов")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',[_c('i',{staticClass:"fa fa-plus",attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }