<template>
  <div class="history__wrapper">
    <div class="close__history" @click="$emit('close')">
      <i class="fa fa-times"></i>
    </div>
    <div class="history__content">
      <div
        class="history__item__wrapper"
        v-for="item in data"
        :key="item.history_order_id"
      >
        <div class="history__item">
          <div class="header">
            <div class="icon" v-html="func_get_icon2(item)"></div>
            <div class="date">{{ func_get_date(item) }}</div>
            <div
              class="title"
              :style="{
                color:
                  item.history !== 'Завершен' &&
                  item.history !== 'Завершен неудачно'
                    ? '#1d7596'
                    : '',
              }"
            >
              {{ item.history }}
            </div>
          </div>
          <div class="body">
            {{
              item.master
                ? "(" + item.master + ")"
                : Number(item.summ)
                ? "(+" + Number(item.summ).toFixed(2) + ")"
                : ""
            }}
          </div>
        </div>
        <div
          class="block__line"
          v-if="
            item.history != 'Завершен неудачно' && item.history != 'Завершен'
          "
        >
          <span class="col__blue"></span>
          <span class="col__blue"></span>
          <span class="col__blue"></span>
        </div>
      </div>
      <div
        class="history__item__wrapper"
        v-if="
          status_end &&
          status_end.status != 'Готов' &&
          status_end.status != 'Завершен неудачно' &&
          status_end.status != 'Завершен'
        "
      >
        <div class="history__item">
          <div class="header2">
            <div class="icon" v-html="func_get_icon(status_end)"></div>
            <div class="date" v-html="func_get_title(status_end)"></div>
          </div>
          <div class="body" v-html="func_get_days()"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order_id: String,
  },
  data: () => ({
    data: null,
    status_end: null,
  }),
  methods: {
    func_get_data() {
      this.$sendRequest("order.php/get_detail_history", {
        order_id: this.order_id,
      }).then((result) => {
        if (!result) return;
        this.data = result.result;
        this.status_end = result.status;
      });
    },
    func_get_date(obj) {
      if (obj && obj.datetime)
        return (
          new Date(obj.datetime.replace(" ", "T")).toLocaleDateString() +
          " " +
          new Date(obj.datetime.replace(" ", "T")).toLocaleTimeString()
        );
      else return "";
    },
    func_get_icon2(item) {
      if (item.history === "Готов") {
        return '<i class="fa fa-check-circle-o" style="color:green"></i>';
      } else if (item.history === "Завершен") {
        return '<i class="fa fa-check-circle-o" ></i>';
      } else if (item.history === "Завершен неудачно") {
        return '<i class="fa fa-times-circle-o"></i>';
      } else {
        return '<div class="icon_shere"></div>';
      }
    },
    func_get_icon(status) {
      let days = this.func_get_count_day();
      if (
        status.status !== "Готов" &&
        status.status !== "Завершен неудачно" &&
        status.status !== "Завершен"
      ) {
        if (days >= 0) {
          return '<i class="fa fa-clock-o" style="color:#1d7596"></i>';
        } else {
          return '<i class="fa fa-clock-o" style="color:red"></i>';
        }
      }
    },
    func_get_title(status) {
      let days = this.func_get_count_day();
      let date = new Date(
        status.date_end.replace(" ", "T")
      ).toLocaleDateString();

      if (
        status.status !== "Готов" &&
        status.status !== "Завершен неудачно" &&
        status.status !== "Завершен"
      ) {
        return `<span style="color:${
          days >= 0 ? "#328d30" : "#c74545"
        }">Готовность к</span> <span style="color:${
          days >= 0 ? "" : "#c74545"
        }">${date}</span>`;
      }
    },
    func_get_days() {
      let days = this.func_get_count_day();
      if (days >= 0) {
        return `<span>(Осталось ${
          days + " " + this.func_get_word(days)
        })</span>`;
      } else {
        return `<span style="color:red">(Просрочен на ${
          days * -1 + " " + this.func_get_word(days * -1)
        })</span>`;
      }
    },
    func_get_count_day() {
      let date_end =
        this.status_end && this.status_end.date_end
          ? new Date(this.status_end.date_end)
          : null;
      let date_now = new Date();
      let diffInDays = null;
      if (date_end) {
        let oneDay = 1000 * 60 * 60 * 24;
        let diffInTime = date_end.getTime() - date_now.getTime();
        diffInDays = Math.round(diffInTime / oneDay);
      }

      return diffInDays || 0;
    },
    func_get_word(n) {
      n = Math.abs(n) % 100;
      var n1 = n % 10;
      if (n > 10 && n < 20) {
        return "дней";
      }
      if (n1 > 1 && n1 < 5) {
        return "дня";
      }
      if (n1 == 1) {
        return "день";
      }
      return "дней";
    },
  },
  mounted() {
    this.func_get_data();
  },
};
</script>

<style></style>
