<template>
  <div
    class="new__client__popup"
    :class="[$store.state.user_setting.blur ? 'blur' : 'no-blur']"
  >
    <div class="form__add_client__wrapper">
      <div class="header__back_btn">
        <div @click="func_close_add()" class="arrow-7">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="header__title">
        <h2>Новый клиент</h2>
      </div>
      <form @submit.prevent="func_save_client" class="form__client">
        <div class="form__group">
          <label>Имя*</label>
          <input
            :class="{ border__green: link_client }"
            type="text"
            v-model="name_client"
            required
          />
        </div>
        <div class="form__group">
          <label>Телефон*</label>
          <input
            type="tel"
            :class="{ border__green: link_client }"
            v-model="new_client.phone"
            required
          />
        </div>
        <div class="form__group">
          <label>Телефон 2</label>
          <input
            type="tel"
            :class="{ border__green: link_client }"
            v-model="new_client.phone2"
          />
        </div>
        <div class="form__group">
          <label>Пометка</label>
          <input
            type="text"
            :class="{ border__green: link_client }"
            v-model="new_client.comment"
          />
        </div>
        <p
          class="msg"
          :class="{ color__green: link_client }"
          v-show="msg !== ''"
        >
          {{ msg }}
        </p>
        <div class="button__wrap">
          <button :class="{ border__green: link_client }">
            {{ link_client ? "К клиенту" : "Добавить" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg: "",
      new_client: {
        name: "",
        phone: "",
        phone2: "",
        comment: "",
      },
      link_client: null,
    };
  },
  methods: {
    func_save_client() {
      let client = this.new_client;
      if (this.link_client) {
        this.$emit("show_client", this.link_client);
        return;
      }
      this.link_client = null;
      if (client.name && client.phone) {
        let url = "order.php/save_customer";
        this.$sendRequest(url, {
          customer: client.name,
          phone: client.phone,
          phone2: client.phone2,
          comment: client.comment,
        }).then((result) => {
          if (!result) return;
          this.msg = result.msg;
          if (result.success) {
            this.$emit("add_client", result.data);
            this.new_client.name =
              this.new_client.phone =
              this.new_client.phone2 =
              this.new_client.comment =
                "";
            this.func_close_add();
          } else {
            this.link_client = result.customer_id;
          }
        });
      } else {
        this.msg = "Заполните обязательные поля";
      }
    },
    func_close_add() {
      this.$emit("close_client");
    },
  },
  computed: {
    name_client: {
      get() {
        return this.new_client.name;
      },
      set(val) {
        this.link_client = null;
        this.new_client.name = val;
      },
    },
  },
};
</script>

<style></style>
