var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order__card",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('show_detail')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!!Number(_vm.obj.fire)),expression:"!!Number(obj.fire)"}],staticClass:"order__fire"},[_c('img',{attrs:{"src":"images/flame.png","width":"35","height":"35","alt":"lorem"}})]),_c('div',{staticClass:"order__number",style:({ color: _vm.obj.status_color })},[_vm._v(" "+_vm._s(_vm.obj.order_num)+" ")]),_c('div',{staticClass:"order__gajet",attrs:{"id":"gajet"}},[_vm._v(_vm._s(_vm.obj.device))]),_c('div',{staticClass:"order__status",style:({
      background: _vm.USER_STATE.status.find(
        function (x) { return Number(x.status_user_id) === Number(_vm.obj.status_id); }
      )
        ? _vm.USER_STATE.status.find(
            function (x) { return Number(x.status_user_id) === Number(_vm.obj.status_id); }
          ).color
        : '',
    })},[_vm._v(" "+_vm._s(_vm.USER_STATE.status.find( function (x) { return Number(x.status_user_id) === Number(_vm.obj.status_id); } ) ? _vm.USER_STATE.status.find( function (x) { return Number(x.status_user_id) === Number(_vm.obj.status_id); } ).status : "")+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }