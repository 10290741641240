<template>
  <div class="field__names__wrapper">
    <Loader :show="progress" />
    <div class="groups__wrap" v-if="fields">
      <h4>Группы услуг</h4>
      <div class="form__group">
        <input
          type="text"
          placeholder="Группа"
          v-model="fields.group_name"
          @change="func_save"
        />
      </div>
      <div class="form__group">
        <input
          type="text"
          placeholder="Подгруппа"
          v-model="fields.device"
          @change="func_save"
        />
      </div>
    </div>
    <div class="fileld__names" v-if="fields">
      <h4>Поля заказа</h4>
      <div class="form__group">
        <div class="form__group">
          <input
            type="text"
            placeholder="IMEI"
            v-model="fields.imei"
            @change="func_save"
          />
        </div>
        <div class="form__group">
          <input
            type="text"
            placeholder="Пароль"
            v-model="fields.password"
            @change="func_save"
          />
        </div>
        <div class="form__group">
          <input
            type="text"
            placeholder="Комментарий"
            v-model="fields.comment"
            @change="func_save"
          />
        </div>
        <div class="form__group">
          <input
            type="text"
            placeholder="Неисправность"
            v-model="fields.crash"
            @change="func_save"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/loader";
export default {
  components: {
    Loader,
  },
  data() {
    return {
      fields: null,
      progress: true,
    };
  },
  methods: {
    func_get_fields() {
      this.progress = true;
      let url = "order.php/get_fields";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.fields = result;
        this.progress = false;
      });
    },
    func_save() {
      let url = "order.php/save_fields";
      this.$sendRequest(url, this.fields).then((res) => {
        if (!res) return;
        this.$store.commit("CHANGE_FIELDS", res);
      });
    },
  },
  mounted() {
    this.func_get_fields();
  },
};
</script>

<style></style>
