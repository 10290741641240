<template>
  <div class="modal__code__popup">
    <div class="modal__code__form">
      <div class="header__title">
        <h2>Код отправлен на E-MAIL</h2>
      </div>
      <form @submit.prevent="func_send_code()" class="form__code">
        <div class="form__group">
          <label>Код подтверждения*</label>
          <input
            type="text"
            v-model="code"
            required
            :class="{ error_input: error_msg }"
          />
        </div>
        <div class="timer__wrap">
          <span v-if="!get_code">{{ func_get_time() }}</span>
          <a v-else @click="func_get_code()">Отправить код еще раз</a>
        </div>
        <div class="button__wrap">
          <button type="submit">Продолжить регистрацию</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: String,
    login: String,
  },
  data: () => ({
    code: "",
    msg: "",
    timer: null,
    time: 0,
    get_code: true,
    error_msg: null,
  }),
  methods: {
    func_get_time() {
      return `00:${this.time < 10 ? "0" + this.time : this.time}`;
    },
    func_get_code() {
      if (!this.get_code || !this.email) return;

      this.$sendRequest("user.php/registry2", {
        email: this.email,
      }).then((result) => {
        if (result.success) this.func_start_timer();
        else this.msg = result.msg;
      });
    },
    func_send_code() {
      this.error_msg = null;
      this.$sendRequest("user.php/registry2", {
        email: this.email,
        code: this.code,
        login: this.login,
      }).then((result) => {
        if (result.success) {
          this.code = "";
          this.timer = null;
          this.$emit("step", 2);
        } else {
          this.error_msg = result.msg;
        }
      });
    },
    func_start_timer() {
      this.time = 59;
      this.get_code = false;
      this.timer = setInterval(() => {
        if (this.time <= 0) {
          clearInterval(this.timer);
          this.get_code = true;
          return;
        }
        this.time--;
      }, 1000);
    },
  },
  mounted() {
    this.func_get_code();
  },
};
</script>

<style></style>
