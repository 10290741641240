<template>
  <div class="modal__products__wrapper">
    <div class="modal__product__content">
      <div class="header__back_btn">
        <div @click="$emit('close_modal')" class="arrow-7">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="header__title">
        <h2>Товары в этом заказе</h2>
      </div>
      <div class="products__list">
        <table>
          <thead>
            <tr>
              <th style="width: 15%">Артикул</th>
              <th style="width: 60%">Наименование</th>
              <th style="width: 15%">Цена</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in products_list" :key="index">
              <td>{{ item.article }}</td>
              <td>{{ item.product }}</td>
              <td>{{ Number(item.price || 0).toFixed(2) }}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td>{{ func_get_summ() }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order_id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      products_list: [],
    };
  },
  methods: {
    func_get_data() {
      if (!this.order_id) return;
      this.$sendRequest("order.php/get_products_finance", {
        order_id: this.order_id,
      }).then((result) => {
        if (!result) return;
        this.products_list = result;
      });
    },
    func_get_summ() {
      if (this.products_list.length === 0) return 0;
      let result_summ = 0;
      this.products_list.forEach(function (item) {
        result_summ += Number(item.price || 0);
      });
      return Number(result_summ).toFixed(2);
    },
  },
  mounted() {
    this.func_get_data();
  },
};
</script>

<style></style>
