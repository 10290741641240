<template>
  <div class="order__card" type="button" @click="$emit('show_detail')">
    <div class="order__fire" v-show="!!Number(obj.fire)">
      <img src="images/flame.png" width="35" height="35" alt="lorem" />
    </div>
    <div class="order__number" v-bind:style="{ color: obj.status_color }">
      {{ obj.order_num }}
    </div>
    <div class="order__gajet" id="gajet">{{ obj.device }}</div>

    <div
      class="order__status"
      v-bind:style="{
        background: USER_STATE.status.find(
          (x) => Number(x.status_user_id) === Number(obj.status_id)
        )
          ? USER_STATE.status.find(
              (x) => Number(x.status_user_id) === Number(obj.status_id)
            ).color
          : '',
      }"
    >
      {{
        USER_STATE.status.find(
          (x) => Number(x.status_user_id) === Number(obj.status_id)
        )
          ? USER_STATE.status.find(
              (x) => Number(x.status_user_id) === Number(obj.status_id)
            ).status
          : ""
      }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    obj: Object,
  },
  computed: {
    ...mapGetters(["USER_STATE"]),
  },
};
</script>

<style></style>
