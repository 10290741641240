<template>
  <div class="filter__wrapper">
    <div class="filter__close" @click="$emit('close_filter')">
      <i class="fa fa-window-close" aria-hidden="true"></i>
    </div>
    <div class="filter__item">
      <label for="">По статусу</label>
      <select
        v-model="filter.status_selected"
        @change="func_update_status"
        v-if="status_list.length > 0"
      >
        <option v-for="item in status_list" :key="item.id" :value="item.id">
          {{ item.value }}
        </option>
      </select>
    </div>
    <div class="filter__item">
      <label for="">Поиск по номеру</label>
      <input type="text" placeholder="Поиск" v-model="search" />
    </div>
    <div class="filter__item">
      <label>По дате</label>
      <div class="date__content">
        <datePicker
          @date_start="func_update_date"
          :update_picker="update_picker"
        />
        <div class="clear__date" @click="func_clear_date">
          <i class="fa fa-times" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import datePicker from "@/components/datePicker.vue";
export default {
  components: {
    datePicker,
  },
  data() {
    return {
      update_picker: false,
      filter: {
        status_selected: "0",
        search: "",
        date_end: "",
      },
      status_list: [],
    };
  },
  methods: {
    func_clear_date() {
      this.filter.date_end = "";
      this.update_picker = true;
      setTimeout(() => {
        this.update_picker = false;
      }, 200);
    },
    get_status_list() {
      let url = "order.php/get_status";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        result.unshift({ id: 0, value: "Все" });
        this.status_list = result;
      });
    },
    func_update_status() {
      this.func_update_filter();
    },
    func_update_filter() {
      this.$emit("change_filter", this.filter);
    },
    func_update_date(date_start) {
      this.filter.date_end = date_start;
      this.func_update_filter();
    },
  },
  mounted() {
    this.get_status_list();
  },
  computed: {
    search: {
      get: function () {
        return this.filter.search;
      },
      set: function (val) {
        this.filter.search = val;
        this.func_update_filter();
      },
    },
  },
};
</script>

<style></style>
