<template>
  <div class="loader__wrapper" v-if="show">
    <div class="loading1"></div>
    <!-- <div class="prices-cir">
      <div class="price-pr4">Загрузка..</div>
      <div class="arrow-10">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
};
</script>

<style></style>
