<template>
  <div
    class="new__order__popup"
    :class="[$store.state.user_setting.blur ? 'blur' : 'no-blur']"
  >
    <div class="form__add_order__wrapper">
      <div class="header__back_btn">
        <div @click="func_close_add()" class="arrow-7">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="header__title">
        <h2>Добавить новый заказ</h2>
      </div>
      <form @submit.prevent="func_save_order()" class="form__order">
        <div class="form__group">
          <label>Мастер*</label>
          <select v-if="user_arr.length === 0" @click="func_show_setting(6)">
            <option selected="true" disabled>
              Отредактируйте в настройках
            </option>
          </select>
          <select v-else v-model="new_order.master_id" required>
            <option value="0" selected>Не выбран</option>
            <option
              :value="item.master_id"
              v-for="item in user_arr"
              :key="item.id"
            >
              {{ item.master }}
            </option>
          </select>
        </div>
        <div class="form__group">
          <label>{{ FIELDS_STATE.group_name }}*</label>
          <select v-if="group_arr.length === 0" @click="func_show_setting(4)">
            <option selected="true" disabled>
              Отредактируйте в настройках
            </option>
          </select>
          <select v-else v-model="new_order.group_id" required>
            <option :value="item.id" v-for="item in group_arr" :key="item.id">
              {{ item.value }}
            </option>
          </select>
        </div>
        <div class="form__group">
          <label>{{ FIELDS_STATE.device }}*</label>
          <select v-if="device_arr.length === 0" @click="func_show_setting(4)">
            <option selected="true" disabled>
              Отредактируйте в настройках
            </option>
          </select>
          <select v-else v-model="new_order.device_id" required>
            <option
              :value="item.id"
              v-for="item in device_arr.filter(
                (x) => x.group_id === new_order.group_id
              )"
              :key="item.id"
            >
              {{ item.value }}
            </option>
          </select>
        </div>
        <div class="form__group">
          <label>Клиент</label>
          <input
            type="text"
            v-model="get_customer_name"
            readonly
            class="blue"
          />
          <span>
            <i
              class="fa fa-pencil-square-o edit__icon"
              aria-hidden="true"
              @click="func_link_user"
            ></i
          ></span>
        </div>
        <div class="form__group">
          <label>Телефон</label>
          <input type="tel" v-model="customer.phone" readonly class="blue" />
        </div>
        <div class="form__group">
          <label>Телефон 2</label>
          <input type="tel" v-model="customer.phone2" readonly class="blue" />
        </div>
        <div class="form__group">
          <label>{{ FIELDS_STATE.imei }}</label>
          <input type="text" v-model="new_order.imei" />
        </div>
        <div class="form__group">
          <label>Пред.стоимость ремонта</label>
          <input type="number" v-model="new_order.price" />
        </div>
        <div class="form__group">
          <label>Предоплата</label>
          <input type="number" v-model="new_order.prepayment" />
        </div>
        <div class="form__group">
          <label>{{ FIELDS_STATE.crash }}</label>
          <input type="text" v-model="new_order.crash" />
        </div>
        <div class="form__group">
          <label>{{ FIELDS_STATE.comment }}</label>
          <input type="text" v-model="new_order.comment" />
        </div>
        <div class="form__group">
          <label>{{ FIELDS_STATE.password }}</label>
          <input type="text" v-model="new_order.password" />
        </div>
        <div class="form__group">
          <label>Дата готовности*</label>
          <input type="date" v-model="new_order.date_end" required />
        </div>
        <div class="button__wrap">
          <button>Добавить заказ</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Select from "@/components/select";
import { mapGetters } from "vuex";
export default {
  component: {
    Select,
  },
  props: {
    customer: Object,
  },
  data() {
    return {
      user_arr: [],
      device_arr: [],
      group_arr: [],
      new_order: {
        master_id: 0,
        device_id: 0,
        phone: "",
        phone2: "",
        imei: "",
        price: "",
        prepayment: "",
        comment: "",
        crash: "",
        password: "",
        date_end: new Date(),
      },
    };
  },
  methods: {
    func_show_setting(id) {
      this.$router.push({ name: "setting", params: { id } });
    },
    func_get_data() {
      let url = "order.php/get_add_order";
      this.$sendRequest(url, {}).then((result) => {
        if (result) {
          this.device_arr = result.device;
          this.group_arr = result.groups;
          this.user_arr = result.users;
        }
      });
    },
    func_save_order() {
      let url = "order.php/add_order";
      let param = { ...this.new_order, customer_id: this.customer.customer_id };
      this.$sendRequest(url, param).then((result) => {
        if (result) {
          this.$emit("add_order", result);
        }
      });
    },
    func_close_add() {
      this.$emit("close_add");
    },
    func_link_user() {
      if (this.customer) this.$emit("customer_show", this.customer);
    },
  },
  mounted() {
    this.func_get_data();
  },
  computed: {
    ...mapGetters(["FIELDS_STATE"]),
    get_customer_name: {
      get() {
        return (
          "ID " + this.customer.customer_num + " | " + this.customer.customer
        );
      },
    },
  },
};
</script>

<style></style>
