<template>
  <select
    v-model="selected"
    v-if="USER_STATE.status.length > 0"
    @change="func_change()"
    :style="{
      border:
        '1px solid ' +
        (USER_STATE.status.find(
          (x) => Number(x.status_user_id) === Number(item.status_id)
        )
          ? USER_STATE.status.find(
              (x) => Number(x.status_user_id) === Number(item.status_id)
            ).color
          : ''),
    }"
  >
    <option
      v-for="item in USER_STATE.status"
      :key="item.status_user_id"
      :value="item.status_user_id"
    >
      {{ item.status }}
    </option>
  </select>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    arr_p: {
      type: Array,
      required: true,
    },
    selected_p: {
      type: String,
      required: true,
    },
    device: {
      type: Boolean,
      required: false,
    },
    item: {
      type: Object,
      required: false,
    },
  },
  watch: {
    selected_p(val) {
      this.selected = Number(val);
    },
  },
  data() {
    return {
      arr: [],
      selected: null,
    };
  },
  methods: {
    func_load_data() {
      this.arr = this.arr_p;
      this.selected = Number(this.selected_p);
    },
    func_change() {
      this.$emit("change", {
        id: this.selected,
        value: this.arr.find((x) => x.id === this.selected).value,
        device: this.device,
        item: this.item,
      });
    },
  },
  mounted() {
    this.func_load_data();
  },
  computed: {
    ...mapGetters(["USER_STATE"]),
  },
};
</script>

<style></style>
