<template>
  <div class="sms__setting__wrapper">
    <transition name="fade_up1">
      <MessageList v-show="show_message" />
    </transition>
    <div class="sms__setting__container">
      <div class="close__btn" @click="$emit('close_sms_setting')">
        <i class="fa fa-window-close" aria-hidden="true"></i>
      </div>
      <div class="content" v-if="sms">
        <div class="header">
          <h4>Ваш API ключ</h4>
          <div class="input__sms">
            <input type="text" v-model="sms.sms_api" @change="func_save_data" />
            <i class="fa fa-check" aria-hidden="true"></i>
          </div>
          <p>
            Получить API ключ можно на сайте
            <a target="_blank" href="https://sms.ru/">sms.ru</a>
          </p>
        </div>
        <div class="header" style="margin-top: 10px">
          <div class="input__sms">
            <input
              type="text"
              v-model="sms.sms_send_user"
              @change="func_save_data"
              placeholder="Имя отправителя"
            />
          </div>
        </div>

        <div class="sms__item">
          <div class="check">
            <div class="toggle-button-cover">
              <div class="button-cover">
                <div class="button b2" id="button-12">
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="sms.sms_add"
                    @change="func_save_data"
                  />
                  <div class="knobs">
                    <span></span>
                  </div>
                  <div class="layer"></div>
                </div>
              </div>
            </div>
            <label>Отправлять СМС автоматически, когда заказ добавлен</label>
          </div>
          <textarea
            rows="3"
            v-model="sms.sms_add_text"
            @change="func_save_data"
          ></textarea>
        </div>
        <div class="sms__item">
          <div class="check">
            <div class="toggle-button-cover">
              <div class="button-cover">
                <div class="button b2" id="button-12">
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="sms.sms_complite"
                    @change="func_save_data"
                  />
                  <div class="knobs">
                    <span></span>
                  </div>
                  <div class="layer"></div>
                </div>
              </div>
            </div>
            <label for=""
              >Отправлять СМС автоматически, когда статус заказа 'Готов'</label
            >
          </div>
          <textarea
            rows="3"
            v-model="sms.sms_complite_text"
            @change="func_save_data"
          ></textarea>
        </div>
        <ul class="tegs__sms">
          <li><span>*order_id*</span> - номер заказа</li>
          <li><span>*data_in*</span> - дата создани заказа</li>
          <li><span>*data_out*</span> - дата завершения заказа</li>
          <li><span>*p_group*</span> - устройство</li>
          <li><span>*pp_group*</span> - группа устройств</li>
          <li><span>*insert1*</span> - imei</li>
          <li><span>*insert2*</span> - неисправность</li>
          <li><span>*comment*</span> - коментарий</li>
          <li><span>*price*</span> - цена</li>
          <li><span>*p_price*</span> - предоплата</li>
          <li><span>*pr_price*</span> - предварительная стоимость</li>
          <li><span>*client_name*</span> - имя клиента</li>
          <li><span>*phone1*</span> - телефон</li>
          <li><span>*phone2*</span> - телефон2</li>
          <li><span>*master_name*</span> - имя мастера</li>
          <li><span>*fin_price*</span> - общая сумма товаров</li>
        </ul>
        <div class="table__sms">
          <h4>История</h4>
          <table>
            <thead>
              <tr>
                <th>Заказ</th>
                <th>Телефон</th>
                <th>Текст отправленного СМС</th>
              </tr>
            </thead>
            <tbody v-if="sms_list.length > 0">
              <tr v-for="item in sms_list" :key="item.sms_log_id">
                <td>{{ item.order_id }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.text }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageList from "@/components/message_list";
export default {
  components: {
    MessageList,
  },
  data() {
    return {
      sms: null,
      sms_list: [],
      show_message: false,
    };
  },
  methods: {
    fun_get_data() {
      let url = "order.php/get_sms";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        if (result.res) {
          result.res.sms_add = !!Number(result.res.sms_add);
          result.res.sms_complite = !!Number(result.res.sms_complite);
          result.res.sms_add = !result.res.sms_add;
          result.res.sms_complite = !result.res.sms_complite;
          this.sms = result.res;
        }
        if (result.sms_list) {
          this.sms_list = result.sms_list;
        }
      });
    },

    func_save_data() {
      this.show_message = true;
      let url = "order.php/save_sms";
      let param = { ...this.sms };
      param.sms_add = !param.sms_add ? "1" : "0";
      param.sms_complite = !param.sms_complite ? "1" : "0";
      this.$sendRequest(url, param);
      setTimeout(() => {
        this.show_message = false;
      }, 2000);
    },
  },
  mounted() {
    this.fun_get_data();
  },
};
</script>

<style>
.fade_up1-enter {
  transform: translateY(-100%);
}

.fade_up1-enter-active {
  transition: transform 0.5s;
}

.fade_up1-enter-to {
  transform: translateY(0%);
}

.fade_up1-leave {
  transition: transform 0.5s;
  transform: translateY(0px);
}
.fade_up1-leave-active {
  transition: transform 0.5s;
}

.fade_up1-leave-to {
  transform: translateY(-100%);
}
</style>
