<template>
  <div class="func__wrapper">
    <div class="func__blocks">
      <div class="func__item">
        <div class="img">
          <i class="fa fa-envelope-o" aria-hidden="true" value="СМС"></i>
        </div>
        <div class="text">СМС</div>
        <button @click="show_sms = !show_sms">Настроить</button>
      </div>
      <div class="func__item">
        <div class="img">
          <i class="fa fa-file-text-o" aria-hidden="true" value="СМС"></i>
        </div>
        <div class="text">Приемная квитанция</div>
        <button @click="func_show_edit_print(1)">Настроить</button>
      </div>
      <div class="func__item">
        <div class="img">
          <i class="fa fa-print" aria-hidden="true" value="СМС"></i>
        </div>
        <div class="text">Акт</div>
        <button @click="func_show_edit_print(2)">Настроить</button>
      </div>
      <!-- <div class="func__item">
        <div class="img">
          <i class="fa fa-play" aria-hidden="true"></i>
        </div>
        <div class="text">Радио</div>
        <button>Настроить</button>
      </div> -->
    </div>
    <transition name="fade_down2">
      <SmsSetting v-if="show_sms" @close_sms_setting="show_sms = false" />
    </transition>
    <transition name="fade_down2">
      <PrintSetting
        v-if="show_print"
        :type="type_print"
        @close_print_setting="show_print = false"
      />
    </transition>
  </div>
</template>

<script>
import SmsSetting from "@/components/SettingUser/sms_setting.vue";
import PrintSetting from "@/components/SettingUser/print_setting.vue";
export default {
  components: {
    SmsSetting,
    PrintSetting,
  },
  data() {
    return {
      show_sms: false,
      show_print: false,
      type_print: null,
    };
  },
  methods: {
    func_show_edit_print(type) {
      this.type_print = Number(type);
      this.show_print = true;
    },
  },
};
</script>

<style>
.fade_down2-enter-active,
.fade_down2-leave-active {
  transition: 0.5s;
  transform: translateY(0px);
}
.fade_down2-enter, .fade_down2-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  transform: translateY(-100%);
}
.func__wrapper .func__blocks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
</style>
