<template>
  <div class="status__wrapper">
    <Loader :show="progress" />
    <div class="status__header">
      <h4>Редактор статусов</h4>
    </div>
    <div class="status__body">
      <form @submit.prevent="func_add_status">
        <div class="input__add">
          <input type="text" v-model="new_status" required />
          <button><i class="fa fa-plus" aria-hidden="true"></i></button>
        </div>
      </form>
      <ul class="status__content" v-if="status_list.length > 0">
        <li v-for="item in status_list" :key="item.id">
          <div
            class="delete_status"
            v-if="
              item.value !== 'Готов' &&
              item.value !== 'Завершен неудачно' &&
              item.value !== 'Завершен' &&
              item.value !== 'Принят'
            "
            @click="func_delete_status(item)"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
          <input
            type="text"
            @change="func_update_status(item)"
            v-model="item.value"
            :readonly="
              item.value == 'Готов' ||
              item.value == 'Завершен неудачно' ||
              item.value == 'Завершен' ||
              item.value == 'Принят'
            "
          />
          <button :style="{ background: item.color }">
            <input
              type="color"
              v-model="item.color"
              @change="func_update_status(item)"
            />
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Loader from "@/components/loader";
export default {
  components: {
    Loader,
  },
  data() {
    return {
      status_list: [],
      new_status: "",
      progress: true,
    };
  },
  methods: {
    func_add_status() {
      let url = "order.php/add_status";
      this.$sendRequest(url, {
        status: this.new_status,
      }).then((result) => {
        if (!result) return;
        this.status_list.splice(1, 0, result.status);
        this.func_edit_status_main();
      });
    },
    func_get_status() {
      this.progress = true;
      let url = "order.php/get_status";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.status_list = result;
        this.new_status = "";
        this.progress = false;
      });
    },
    func_delete_status(item) {
      let url = "order.php/remove_status";
      this.$sendRequest(url, { status_id: item.id }).then((result) => {
        if (!result) return;
        if (result.success) {
          this.status_list.splice(
            this.status_list.findIndex((x) => x.id === item.id),
            1
          );
          this.func_edit_status_main();
        }
      });
    },
    func_update_status(item) {
      if (!item) return;
      let url = "order.php/update_status";
      this.$sendRequest(url, item).then(() => {});
      this.func_edit_status_main();
    },
    func_edit_status_main() {
      let obj = this.USER_STATE;
      let status_new = [...this.status_list];

      status_new.forEach((item) => {
        item.status_user_id = item.id;
        item.status = item.value;
      });

      obj.status = status_new;
      this.$store.commit("CHANGE_USER", obj);
    },
  },
  mounted() {
    this.func_get_status();
  },
  computed: {
    ...mapGetters(["USER_STATE"]),
  },
};
</script>

<style></style>
