<template>
  <div
    class="print__setting__wrapper"
    :class="[$store.state.user_setting.blur ? 'blur' : 'no-blur']"
  >
    <transition name="fade_up1">
      <MessageList v-show="show_message" />
    </transition>
    <div class="print__setting__container">
      <div class="close__btn" @click="$emit('close_print_setting')">
        <i class="fa fa-window-close" aria-hidden="true"></i>
      </div>
      <div class="print__content">
        <div class="print__table">
          <tinymce id="d1" v-model="print" :other_options="options"></tinymce>
          <button class="send" @click="func_save">Сохранить</button>
        </div>
        <div class="print__description">
          <ul>
            <li><span>*order_id*</span> - номер заказа</li>
            <li><span>*data_in*</span> - дата создани заказа</li>
            <li><span>*data_out*</span> - дата завершения заказа</li>
            <li><span>*p_group*</span> - устройство</li>
            <li><span>*pp_group*</span> - группа устройств</li>
            <li><span>*insert1*</span> - imei</li>
            <li><span>*insert2*</span> - неисправность</li>
            <li><span>*comment*</span> - коментарий</li>
            <li><span>*price*</span> - цена</li>
            <li><span>*p_price*</span> - предоплата</li>
            <li><span>*pr_price*</span> - предварительная стоимость</li>
            <li><span>*client_name*</span> - имя клиента</li>
            <li><span>*phone1*</span> - телефон</li>
            <li><span>*phone2*</span> - телефон2</li>
            <li><span>*master_name*</span> - имя мастера</li>
            <li><span>*table_order*</span> - таблица с товарами</li>
            <li><span>*fin_price*</span> - общая сумма товаров</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageList from "@/components/message_list";
export default {
  props: {
    type: Number,
  },
  components: {
    MessageList,
  },
  data() {
    return {
      print: "",
      show_message: false,
      options: {
        height: 400,
      },
    };
  },
  methods: {
    func_get_data() {
      let url = "order.php/get_print_setting";
      this.$sendRequest(url, {
        type: this.type,
      }).then((result) => {
        if (!result) return;
        this.print = result;
      });
    },
    func_save() {
      this.show_message = true;
      let url = "order.php/save_print_setting";
      this.$sendRequest(url, {
        type: this.type,
        print: this.print,
      });
      setTimeout(() => {
        this.show_message = false;
      }, 2000);
    },
  },
  mounted() {
    this.func_get_data();
  },
};
</script>

<style>
.fade_up1-enter {
  transform: translateY(-100%);
}

.fade_up1-enter-active {
  transition: transform 0.5s;
}

.fade_up1-enter-to {
  transform: translateY(0%);
}

.fade_up1-leave {
  transition: transform 0.5s;
  transform: translateY(0px);
}
.fade_up1-leave-active {
  transition: transform 0.5s;
}

.fade_up1-leave-to {
  transform: translateY(-100%);
}
</style>
