<template>
  <div class="table__style">
    <DetailModal
      @cancel="func_detail_cancel"
      @save="func_detail_save"
      v-if="modal_detail_show"
      :option="detail_option"
    />
    <table>
      <thead>
        <tr>
          <th>Заказ</th>
          <th>Создан</th>
          <th>Срок</th>
          <th></th>
          <th>Статус</th>
          <th>{{ FIELDS_STATE.device }}</th>
          <th>Пароль</th>
          <th>Неисправность</th>
          <th>Клиент</th>
          <th>Телефон</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in card_list" :key="item.order_id">
          <td @click="func_get_detail(item)">{{ item.order_num }}</td>
          <td @click="func_get_detail(item)">
            {{ new Date(iosDate(item.date_create)).toLocaleDateString() }}
            <span>{{
              new Date(iosDate(item.date_create)).toLocaleTimeString()
            }}</span>
          </td>
          <td
            v-html="func_get_count_day_color(item)"
            @click="func_get_detail(item)"
          ></td>
          <td
            v-html="func_get_count_day_img(item)"
            @click="func_get_detail(item)"
          ></td>
          <td>
            <select
              v-model="item.status_id"
              v-if="USER_STATE.status.length > 0"
              :disabled="func_get_status_disable(item)"
              @change="func_check_status(item)"
              :style="{
                border:
                  '1px solid ' +
                  (USER_STATE.status.find(
                    (x) => Number(x.status_user_id) === Number(item.status_id)
                  )
                    ? USER_STATE.status.find(
                        (x) =>
                          Number(x.status_user_id) === Number(item.status_id)
                      ).color
                    : ''),
              }"
            >
              <option
                v-for="item2 in USER_STATE.status"
                :key="item2.status_user_id"
                :value="item2.status_user_id"
              >
                {{ item2.status }}
              </option>
            </select>
            <!-- <Select
              v-if="status_list.length > 0"
              :arr_p="status_list"
              :selected_p="item.status_id"
              :item="item"
              @change="func_set_status"
            /> -->
          </td>
          <!-- <td @click="func_get_detail(item)">{{ item.group_name }}</td> -->
          <td @click="func_get_detail(item)">{{ item.device }}</td>
          <td @click="func_get_detail(item)">{{ item.password }}</td>
          <td @click="func_get_detail(item)">{{ item.crash }}</td>
          <td>
            <button @click="func_show_customer(item)">
              <i class="fa fa-user-o" aria-hidden="true"></i></button
            >{{ item.customer }}
          </td>
          <td @click="func_get_detail(item)">{{ item.phone }}</td>
          <td>
            <button @click="func_get_print(1, item.order_id)">
              <i aria-hidden="true" class="fa fa-file-text-o"></i>
            </button>
          </td>
          <td>
            <button @click="func_get_print(2, item.order_id)">
              <i aria-hidden="true" class="fa fa-print"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div id="print" v-show="false" v-html="print"></div>
  </div>
</template>

<script>
// import Select from "@/components/select";
import DetailModal from "@/components/Main/details_modal.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    card_list: Array,
  },
  components: {
    // Select,
    DetailModal,
  },
  data() {
    return {
      status_list: [],
      print: null,
      modal_detail_show: false,
      detail_option: null,
    };
  },
  methods: {
    iosDate(date) {
      return date ? date.replace(" ", "T") : "";
    },
    func_check_status(obj) {
      let status = this.status_list.find(
        (x) => Number(x.id) === Number(obj.status_id)
      );
      if (
        status &&
        (status.value === "Завершен" || status.value === "Завершен неудачно")
      ) {
        obj.value = status.value;
        this.detail_option = obj;
        this.modal_detail_show = true;
      } else {
        this.func_set_status(obj);
      }
    },
    func_detail_cancel(option) {
      let status_old = this.status_list.find((x) => x.value === option.status);
      this.card_list.forEach(function (item) {
        if (Number(item.order_id) === Number(option.order_id)) {
          item.status_id = status_old.id;
          item.status_color = status_old.color;
          item.status = status_old.value;
        }
      });
      this.modal_detail_show = false;
    },
    func_detail_save(option) {
      let status_new = this.status_list.find(
        (x) => Number(x.id) === Number(option.status_id)
      );
      let obj = null;
      this.card_list.forEach(function (item) {
        if (Number(item.order_id) === Number(option.order_id)) {
          item.status_id = status_new.id;
          item.status_color = status_new.color;
          item.status = status_new.value;
          item.amount_id = option.type_amount;
          item.fire = 0;

          obj = { ...item };
        }
      });
      this.modal_detail_show = false;
      obj.comment1 = option.comment;
      obj.itog = option.itog;

      if (obj) this.func_set_status(obj);
    },
    func_get_status_disable(obj) {
      if (
        obj &&
        (obj.status === "Завершен" || obj.status === "Завершен неудачно")
      ) {
        return true;
      } else {
        return false;
      }
    },
    func_get_status() {
      let url = "order.php/get_status";

      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.status_list = result;
      });
    },
    func_get_count_day_color(item) {
      let diffInDays = this.func_get_day(item);
      let color = "";

      if (diffInDays > 1) {
        color = "#328d30";
      } else if (diffInDays === 1) {
        color = "#dd8b37";
      } else {
        color = "#c74545";
      }

      return item.status === "Завершен" || item.status === "Завершен неудачно"
        ? ""
        : "<span style='color:" +
            color +
            ";'>" +
            diffInDays.toString() +
            " д.</span>";
    },
    func_get_count_day_img(item) {
      let diffInDays = this.func_get_day(item);
      let img = "";

      if (diffInDays > 1) {
        img = "order-img.png";
      } else if (diffInDays === 1) {
        img = "order-img1.png";
      } else {
        img = "order-img2.png";
      }

      let fire = !!Number(item.fire);
      if (fire) {
        img = "order-flame.png";
      }

      if (item.status === "Завершен") {
        img = "order-img3.png";
      }
      if (item.status === "Готов") {
        img = "okgreen.png";
      }

      if (item.status === "Завершен неудачно") {
        img = "order-img4.png";
      }

      return (
        '<img style="padding:5px" src="' + require("@/assets/" + img) + '"/>'
      );
    },
    func_get_day(item) {
      let date_end = new Date(item.date_end);
      let date_now = new Date();

      let oneDay = 1000 * 60 * 60 * 24;
      let diffInTime = date_end.getTime() - date_now.getTime();
      let diffInDays = Math.round(diffInTime / oneDay);
      return diffInDays;
    },
    func_set_status(obj) {
      let status_id = obj.status_id;
      let order_id = obj.order_id;
      let amount_type_id = obj.amount_id;
      let comment1 = obj.comment1;
      let itog = obj.itog;

      let url = "order.php/save_status";
      let param = {
        order_id,
        status_id,
        amount_type_id,
        itog,
        comment1,
      };
      this.$sendRequest(url, param).then((result) => {
        if (!result) return;
        if (result.success) {
          this.card_list.forEach((item) => {
            if (Number(item.order_id) === Number(order_id)) {
              item.status_id = status_id;
              item.status = obj.value;
            }
          });
          let user_state = { ...this.USER_STATE };
          user_state.amount_card = result.amount_card;
          user_state.amount_nal = result.amount_nal;
          this.$store.commit("CHANGE_USER", user_state);
        }
      });
    },
    func_get_detail(item) {
      if (!item) return;

      this.$emit("get_detail", item);
    },
    func_show_customer(obj) {
      let customer_id = obj.customer_id;
      this.$router.push({
        name: "clients",
        params: { customer_id: customer_id },
      });
    },
    func_get_print(type, order_id) {
      let url = "order.php/get_print";
      this.$sendRequest(url, {
        type,
        order_id,
      }).then((result) => {
        if (!result) return;
        this.print = result;
        setTimeout(() => {
          this.$htmlToPaper("print");
        }, 1000);
      });
    },
  },
  mounted() {
    this.func_get_status();
  },
  computed: {
    ...mapGetters(["FIELDS_STATE"]),
    ...mapGetters(["USER_STATE"]),
  },
};
</script>

<style></style>
