<template>
  <div class="users__table__wrapper">
    <div
      class="close__users tooltip"
      @click="$router.push('/')"
      tooltip="Назад"
      tooltip-position="bottom left"
    >
      <i class="fa fa-external-link" aria-hidden="true"></i>
    </div>
    <transition name="add_client">
      <AddClient
        v-if="show_add_client"
        @close_client="show_add_client = false"
        @add_client="func_add_client"
        @show_client="func_show_client"
      />
    </transition>
    <transition name="customer_detail">
      <DetailCustomer
        v-if="show_customer_detail"
        :customer_id="customer.customer_id"
        @close_customer_detail="func_close_customer_detail"
        @update_customer="func_update_customer"
        @customer_add_order="func_add_order_detail"
      />
    </transition>
    <transition name="fade_up">
      <AddOrder
        :customer="customer"
        v-if="show_add_order"
        @close_add="show_add_order = false"
        @add_order="func_add_order"
        @customer_show="customer_show"
      />
    </transition>
    <div class="header__user">
      <transition name="search_user">
        <input
          type="text"
          placeholder="Поиск по ID"
          v-show="search.show"
          v-model="search.search_id"
        />
      </transition>
      <div class="buttons">
        <button
          class="btn__add tooltip"
          @click="show_add_client = true"
          tooltip="Добавить клиента"
          tooltip-position="bottom left"
        >
          <i class="fa fa-user-plus" aria-hidden="true"></i>
        </button>
        <button
          class="btn__search tooltip"
          tooltip="Фильтр"
          tooltip-position="bottom right"
          @click="search.show = !search.show"
        >
          <i class="fa fa-search" aria-hidden="true"></i>
        </button>
      </div>

      <transition name="search_user">
        <input
          type="text"
          placeholder="Поиск по номеру"
          v-show="search.show"
          v-model="search.search_phone"
        />
      </transition>
    </div>
    <div class="title__header" v-if="client_list.length === 0">
      Добавьте нового клиента
    </div>
    <div class="clients__content">
      <h4>Клиенты</h4>
      <div class="table__clients">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th></th>
              <th></th>
              <th>Имя</th>
              <th>Телефон</th>
              <th>Телефон 2</th>
              <th>Заказов</th>
              <th></th>
              <th>Пометка</th>
            </tr>
          </thead>
          <tbody v-if="filter_list.length > 0">
            <tr
              v-for="item in filter_list"
              :key="item.customer_id"
              :class="{ new_client: Number(item.orders) === 0 }"
            >
              <td>{{ item.customer_num }}</td>
              <td>
                <button class="profile" @click="func_show_customer_detail(item)">
                  <i class="fa fa-id-card-o" aria-hidden="true"></i>
                </button>
              </td>
              <td>
                <button class="add_order" @click="func_show_add_client(item)">
                  Новый заказ
                </button>
              </td>
              <td>{{ item.customer }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.phone2 }}</td>
              <td>{{ item.orders || 0 }}</td>
              <td>
                <div class="review_stars_wrap">
                  <i class="fa fa-star" :class="{ checked: Number(item.rank) === 5 }"></i>
                  <i class="fa fa-star" :class="{ checked: Number(item.rank) === 4 }"></i>
                  <i class="fa fa-star" :class="{ checked: Number(item.rank) === 3 }"></i>
                  <i class="fa fa-star" :class="{ checked: Number(item.rank) === 2 }"></i>
                  <i class="fa fa-star" :class="{ checked: Number(item.rank) === 1 }"></i>
                </div>
              </td>
              <td>{{ item.comment }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import AddClient from "@/components/Users/add_client.vue";
import AddOrder from "@/components/Users/add_order";
import DetailCustomer from "@/components/Users/customer_detail.vue";
export default {
  components: {
    AddClient,
    AddOrder,
    DetailCustomer,
  },
  props: {
    customer_id_show: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      search: {
        show: false,
        search_id: "",
        search_phone: "",
      },
      show_add_client: false,
      show_add_order: false,
      show_customer_detail: false,
      client_list: [],
      customer: null,
    };
  },
  methods: {
    func_show_client(client_id) {
      if (client_id) {
        this.show_add_client = false;
        let customer = this.client_list.find((x) => x.customer_id === client_id);
        if (customer) this.func_show_customer_detail(customer);
      }
    },
    func_add_order_detail(customer) {
      if (!customer) return;
      this.show_customer_detail = false;
      this.func_show_add_client(customer);
    },
    customer_show(customer) {
      this.show_add_order = false;
      this.func_show_customer_detail(customer);
    },
    func_close_customer_detail() {
      this.show_customer_detail = false;

      if (this.customer_id_show) {
        this.$emit("remove_customer_id_show");
      }
    },
    func_get_data() {
      let url = "order.php/get_customers";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.client_list = result;
        if (this.customer_id_show) {
          let customer = this.client_list.find(
            (x) => Number(x.customer_id) === Number(this.customer_id_show)
          );
          this.func_show_customer_detail(customer);
        }
      });
    },
    func_show_add_client(customer) {
      this.customer = customer;
      this.show_add_order = true;
    },
    func_add_client(obj) {
      if (!obj) return;
      this.client_list.unshift(obj);
    },
    func_add_order(order) {
      let customer_id = Number(order.customer_id);
      let obj = this.client_list.find((x) => Number(x.customer_id) === customer_id);
      obj.orders = Number(obj.orders || 0) + 1;

      this.$router.push("/");
    },
    func_show_customer_detail(customer) {
      this.customer = customer;
      this.show_customer_detail = true;
    },
    func_update_customer(obj) {
      let customer_edit = this.client_list.find(
        (x) => Number(x.customer_id) === Number(obj.customer_id)
      );
      if (customer_edit) {
        customer_edit.customer = obj.customer;
        customer_edit.phone = obj.phone;
        customer_edit.phone2 = obj.phone2;
        customer_edit.comment = obj.comment;
        customer_edit.rank = obj.rank;
      }
    },
  },
  mounted() {
    this.func_get_data();
    if (this.$route.params && this.$route.params.customer_id) {
      let customer = { customer_id: this.$route.params.customer_id };
      this.customer = customer;
      this.show_customer_detail = true;
    }
  },
  computed: {
    filter_list() {
      let search_id = !!this.search.search_id;
      let search_phone = !!this.search.search_phone;
      return [...this.client_list].filter((x) => {
        if (search_id && search_phone) {
          if (
            x.customer_num.indexOf(this.search.search_id) !== -1 ||
            x.phone.indexOf(this.search.search_phone) !== -1 ||
            x.phone2.indexOf(this.search.search_phone) !== -1
          ) {
            return true;
          } else {
            return false;
          }
        } else if (search_id && !search_phone) {
          if (
            x.customer_num.toString().indexOf(this.search.search_id.toString()) !== -1
          ) {
            return true;
          } else {
            return false;
          }
        } else if (!search_id && search_phone) {
          if (
            x.phone.indexOf(this.search.search_phone) !== -1 ||
            x.phone2.indexOf(this.search.search_phone) !== -1
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    },
  },
};
</script>

<style>
.search_user-enter {
  transform: translateY(-150%);
}

.search_user-enter-active {
  transition: 0.5s;
  will-change: transform;
}
.search_user-enter-to {
  transform: translateY(0);
}
.search_user-leave {
  transform: translateY(0);
}
.search_user-leave-active {
  transition: 0.3s;
}

.search_user-leave-to {
  transform: translateY(-150%);
}

.add_client-enter {
  transform: translateY(-100%) scale(0);
}

.add_client-enter-active {
  transition: 0.5s;
  will-change: transform;
}
.add_client-enter-to {
  transform: translateY(0) scale(1);
}
.add_client-leave {
  transform: translateY(0) scale(1);
}
.add_client-leave-active {
  transition: 0.3s;
}

.add_client-leave-to {
  transform: translateY(-150%) scale(0);
}

.customer_detail-enter {
  transform: scale(0);
}

.customer_detail-enter-active {
  transition: 0.5s;
  will-change: transform;
}
.customer_detail-enter-to {
  transform: scale(1);
}
.customer_detail-leave {
  transform: scale(1);
}
.customer_detail-leave-active {
  transition: 0.3s;
}

.customer_detail-leave-to {
  transform: scale(0);
}
</style>
