var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.USER_STATE.status.length > 0)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],style:({
    border:
      '1px solid ' +
      (_vm.USER_STATE.status.find(
        function (x) { return Number(x.status_user_id) === Number(_vm.item.status_id); }
      )
        ? _vm.USER_STATE.status.find(
            function (x) { return Number(x.status_user_id) === Number(_vm.item.status_id); }
          ).color
        : ''),
  }),on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.func_change()}]}},_vm._l((_vm.USER_STATE.status),function(item){return _c('option',{key:item.status_user_id,domProps:{"value":item.status_user_id}},[_vm._v(" "+_vm._s(item.status)+" ")])}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }