<template>
  <div class="pay__wrapper" v-if="result">
    <div class="pay__success" v-if="!!Number(result.type)">
      <img :src="require('@/assets/catpaygood.png')" alt="" />
      <p class="pay__summ">
        + {{ Number(result.summ || 0).toFixed(2) }}
        <i class="fa fa-rub" aria-hidden="true"></i>
      </p>
      <p class="pay__text">Баланс успешно пополнен</p>
      <button @click="$router.push('/')">Вернуться</button>
    </div>
    <div class="pay__error" v-else>
      <img :src="require('@/assets/catnopay.png')" alt="" />
      <p class="pay__summ">Возникла ошибка при оплате</p>
      <p class="pay__text">
        Возможно вы ввели неверные данные или недостаточно средств на карте
      </p>
      <button @click="func_pay(result.summ)">Повторить</button>
      <button class="back" @click="$router.push('/')">Вернуться</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      result: null,
    };
  },
  methods: {
    func_get_data() {
      this.$sendRequest("user.php/pay", { id: this.id }).then((result) => {
        if (!result) return;
        this.result = result;
      });
    },
    func_pay(summ) {
      if (isNaN(Number(summ))) return;
      if (Number(summ) < 100) return;

      let url = "order.php/payment";
      this.$sendRequest(url, {
        amount: summ,
      }).then((result) => {
        if (!result) return;
        if (!result.link) return;
        window.open(result.link, "_blank");
      });
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id;
      this.func_get_data();
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style scoped></style>
