<template>
  <div class="profile__wrapper">
    <Loader :show="progress" />
    <div class="profile__header"><h4>Ваш профиль</h4></div>
    <div class="profile__body">
      <div class="left__bar">
        <div class="id__title">
          <h4>Ваш ID:</h4>
          <h3>{{ profile.user_id }}</h3>
        </div>
        <div class="form__group">
          <label>Город</label>
          <input
            type="text"
            required
            v-model="profile.city"
            @change="func_save_profile"
          />
        </div>
        <div class="form__group">
          <label>Название</label>
          <input
            type="text"
            required
            v-model="profile.company"
            @change="func_save_profile"
          />
        </div>
        <div class="form__group">
          <label>E-mail</label>
          <input
            type="text"
            required
            v-model="profile.email"
            @change="func_save_profile"
          />
        </div>
        <div class="form__group">
          <label>Телефон</label>
          <input
            type="text"
            required
            v-model="profile.phone"
            @change="func_save_profile"
          />
        </div>
        <div class="form__group">
          <label>Имя администратора</label>
          <input
            type="text"
            required
            v-model="profile.name"
            @change="func_save_profile"
          />
        </div>
      </div>
      <form @submit.prevent="func_save_title">
        <div class="right__bar">
          <div class="form__group">
            <label>Произвольная запись на главной</label>
            <textarea rows="3" v-model="profile.title"></textarea>
          </div>
          <!-- <div class="form__group">
            <label>Пароль</label>
            <input type="password" required />
          </div>
          <div class="form__group">
            <label>Пароль еще раз</label>
            <input type="password" required />
          </div> -->
          <button>Сохранить</button>
          <p v-if="msg">{{ msg }}</p>
        </div>
      </form>
    </div>
    <hr />
    <div class="profile__resetpass">
      <h4>Изменить пароль</h4>
      <form @submit.prevent="func_update_pass">
        <div class="form__group">
          <label>Старый пароль</label>
          <input
            type="password"
            required
            v-model="pass.old_pass"
            :class="{ error_input: errors.includes(1) }"
          />
        </div>
        <div class="form__group">
          <label>Новый пароль</label>
          <input
            type="password"
            required
            v-model="pass.new_pass"
            :class="{ error_borerror_inputder: errors.includes(2) }"
          />
        </div>
        <div class="form__group">
          <label>Новый пароль еще раз</label>
          <input
            type="password"
            required
            v-model="pass.new_pass2"
            :class="{ error_input: errors.includes(3) }"
          />
        </div>
        <button>Изменить</button>
        <p
          class="update_password__error"
          v-if="this.pass.msg"
          :style="{ color: errors.length ? '#ad2b2b ' : '#4cad2b' }"
        >
          {{ this.pass.msg }}
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/loader";
export default {
  components: {
    Loader,
  },
  data() {
    return {
      profile: {},
      msg: null,
      progress: true,
      send: true,
      errors: [],
      pass: {
        old_pass: "",
        new_pass: "",
        new_pass2: "",
        msg: "",
      },
    };
  },
  methods: {
    func_get_profile() {
      this.progress = true;
      let url = "order.php/get_profile";
      this.$sendRequest(url, {}).then((result) => {
        if (!result) return;
        this.profile = result;
        this.progress = false;
      });
    },
    func_update_pass() {
      if (!this.send) return;
      this.send = false;
      this.errors = [];
      this.pass.msg = "";
      if (this.pass.old_pass && this.pass.new_pass && this.pass.new_pass2) {
        if (this.pass.new_pass === this.pass.new_pass2) {
          let url = "order.php/update_password";
          this.$sendRequest(url, this.pass).then((res) => {
            this.send = true;
            if (!res) return;
            if (res.success) {
              this.pass.old_pass =
                this.pass.new_pass =
                this.pass.new_pass2 =
                  "";
              this.errors = [];
              this.pass.msg = res.msg;
              setTimeout(() => {
                this.pass.msg = "";
              }, 1500);
            } else {
              this.pass.msg = res.msg;
              if (Number(res.type) === 1) {
                this.errors = [1];
              } else if (Number(res.type) === 2) {
                this.errors = [2, 3];
              } else {
                this.errors = [1, 2, 3];
              }
            }
          });
        } else {
          this.errors = [2, 3];
          this.pass.msg = "Пароли не совпадают";
        }
      } else {
        let errors = [];
        if (!this.pass.old_pass) errors.push(1);
        if (!this.pass.new_pass) errors.push(2);
        if (!this.pass.new_pass2) errors.push(3);
        this.errors = errors;
        this.pass.msg = "Заполните все поля";
      }
      this.send = true;
    },
    func_save_profile() {
      let url = "order.php/save_profile";
      this.$sendRequest(url, this.profile);
    },
    func_save_title() {
      let url = "order.php/save_profile_title";
      this.$sendRequest(url, {
        title: this.profile.title,
      }).then((result) => {
        if (!result) return;
        if (result.success) this.msg = result.msg;
        setTimeout(() => {
          this.msg = "";
        }, 2000);
      });
    },
  },
  mounted() {
    this.func_get_profile();
  },
};
</script>

<style></style>
