<template>
  <div class="wrapper__resetpass">
    <div class="resetpass__container">
      <div class="resetpass__header">
        <h4>Восстановить пароль</h4>
      </div>
      <form
        class="resetpass__form"
        v-if="step == 1"
        @submit.prevent="func_send_email()"
      >
        <div class="resetpass__form_input">
          <input type="text" required placeholder="Логин" v-model="login" />
        </div>
        <button>Восстановить</button>
      </form>
      <form
        class="resetpass__form"
        v-if="step == 2"
        @submit.prevent="func_send_code()"
      >
        <div class="resetpass__form_input">
          <input
            type="number"
            required
            placeholder="Код активации"
            v-model="code"
          />
        </div>
        <div class="timer__wrap">
          <span v-if="!get_code">{{ func_get_time() }}</span>
          <a v-else @click="func_send_email()">Отправить код еще раз</a>
        </div>
        <button>Отправить</button>
      </form>
      <form
        class="resetpass__form"
        v-if="step == 3"
        @submit.prevent="func_send_pass()"
      >
        <div class="resetpass__form_input">
          <input
            type="password"
            required
            placeholder="Пароль"
            v-model="password"
          />
        </div>
        <div class="resetpass__form_input">
          <input
            type="password"
            required
            placeholder="Пароль еще раз"
            v-model="password2"
          />
        </div>
        <button>Отправить</button>
      </form>
      <div class="resetpass__right_link">
        <span @click="$router.push('/auth')">Вход</span>
      </div>
      <div class="resetpass__error" v-show="msg">{{ msg }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      login: "",
      email: "",
      msg: "",
      step: 1,
      code: "",
      password: "",
      password2: "",
      timer: null,
      time: 0,
      get_code: false,
    };
  },
  methods: {
    func_get_time() {
      return `00:${this.time < 10 ? "0" + this.time : this.time}`;
    },
    func_start_timer() {
      this.time = 59;
      this.get_code = false;
      this.timer = setInterval(() => {
        if (this.time <= 0) {
          clearInterval(this.timer);
          this.get_code = true;
          return;
        }
        this.time--;
      }, 1000);
    },
    func_send_email() {
      this.msg = "";
      if (this.login) {
        let url = "user.php/resetpass";
        let param = {
          login: this.login,
        };
        this.$sendRequest(url, param).then((result) => {
          if (result.msg) {
            this.msg = result.msg;

            if (result.success) {
              this.step = 2;
              this.email = result.email;
              this.get_code = false;
              this.func_start_timer();
            }
          }
        });
      } else {
        this.msg = "Заполните поле";
      }
    },
    func_send_code() {
      if (this.code) {
        this.msg = "";
        if (Number(this.code)) {
          let url = "user.php/resetpasscode";
          let param = {
            email: this.email,
            code: this.code,
          };
          this.$sendRequest(url, param).then((result) => {
            if (result.success) {
              this.step = 3;
            } else {
              this.msg = result.msg || "";
            }
          });
        } else {
          this.msg = "Не верный формат ввода";
        }
      } else {
        this.msg = "Заполните поле";
      }
    },
    func_send_pass() {
      this.msg = "";
      if (this.password && this.password2) {
        if (this.password !== this.password2) {
          this.msg = "Пароли не совпадают";
          return;
        }
        let url = "user.php/resetpasspass";
        let param = {
          email: this.email,
          login: this.login,
          password: this.password,
        };
        this.$sendRequest(url, param).then((result) => {
          if (result.success) {
            this.$router.push("/auth");
          } else {
            this.msg = result.msg;
          }
        });
      } else {
        this.msg = "Заполните поля";
      }
    },
  },
};
</script>

<style></style>
