<template>
  <div class="finance__datatable__wrapper">
    <productsModal
      :order_id="order_id"
      v-if="show_modal"
      @close_modal="show_modal = false"
    />
    <div class="print" id="print_table" v-show="false">
      <table border="1" style="border-collapse: collapse; width: 100%">
        <tr>
          <th>Дата</th>
          <th></th>
          <th></th>
          <th>Вид оплаты</th>
          <th>Сумма</th>
        </tr>
        <tr v-for="(item, index) in data_filter" :key="index">
          <td>
            {{
              new Date(iosDate(item.datetime)).toLocaleDateString() +
              " " +
              new Date(iosDate(item.datetime)).toLocaleTimeString()
            }}
          </td>
          <td>
            {{
              Number(item.type) === 1
                ? Number(item.order_id) > 0
                  ? "Заказ: " + item.order_num
                  : "Приход"
                : "Расходы"
            }}
          </td>
          <td
            v-html="
              Number(item.order_id) > 0 ? item.products_list : item.comment
            "
          ></td>
          <td>
            {{ Number(item.type_amount) === 1 ? "Картой" : "Наличными" }}
          </td>

          <td>
            {{ (Number(item.type) === 1 ? "" : "-") + " " + item.summ }}
          </td>
        </tr>
        <tr style="border: none">
          <td style="border: none"></td>
          <td style="border: none"></td>
          <td style="border: none"></td>
          <td style="border: none; text-align: right">Расход карта</td>
          <td style="border: 1px solid #000">{{ func_get_summ_type(1) }}</td>
        </tr>
        <tr style="border: none">
          <td style="border: none"></td>
          <td style="border: none"></td>
          <td style="border: none"></td>
          <td style="border: none; text-align: right">Расход наличные</td>
          <td style="border: 1px solid #000">{{ func_get_summ_type(2) }}</td>
        </tr>
        <tr style="border: none">
          <td style="border: none"></td>
          <td style="border: none"></td>
          <td style="border: none"></td>
          <td style="border: none; text-align: right">Приход карта</td>
          <td style="border: 1px solid #000">{{ func_get_summ_type(3) }}</td>
        </tr>
        <tr style="border: none">
          <td style="border: none"></td>
          <td style="border: none"></td>
          <td style="border: none"></td>
          <td style="border: none; text-align: right">Приход наличные</td>
          <td style="border: 1px solid #000">{{ func_get_summ_type(4) }}</td>
        </tr>
      </table>
    </div>
    <table>
      <thead>
        <tr>
          <th>Дата</th>
          <th>Тип</th>
          <th>Тип оплаты</th>
          <th>Сумма</th>
          <th></th>
          <th>Пометка</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data_filter" :key="index">
          <td>
            {{
              new Date(iosDate(item.datetime)).toLocaleDateString() +
              " " +
              new Date(iosDate(item.datetime)).toLocaleTimeString()
            }}
          </td>
          <td>
            {{
              Number(item.type) === 1
                ? Number(item.order_id) > 0
                  ? "Заказ: " + item.order_num
                  : "Приход"
                : "Расходы"
            }}
          </td>
          <td
            :style="{
              color: Number(item.type) === 1 ? '#f9f9a0' : 'rgb(255, 117, 117)',
            }"
          >
            {{ Number(item.type_amount) === 1 ? "Картой" : "Наличными" }}
          </td>
          <td
            :style="{
              color: Number(item.type) === 1 ? '#f9f9a0' : 'rgb(255, 117, 117)',
            }"
          >
            {{ (Number(item.type) === 1 ? "+" : "-") + " " + item.summ }}
          </td>
          <td>
            <i
              v-if="item.order_id && Number(item.order_id) > 0"
              class="fa fa-cubes"
              aria-hidden="true"
              @click="func_view_products(item)"
            ></i>
          </td>
          <td>{{ item.comment }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import productsModal from "@/components/Finance/modal_products.vue";
export default {
  components: {
    productsModal,
  },
  props: {
    date_obj: {
      type: Object,
      required: false,
    },
    update_table: {
      type: Boolean,
      required: true,
    },
    print_table: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      data: [],
      order_id: null,
      show_modal: false,
    };
  },
  watch: {
    date_obj() {
      this.func_get_data();
    },
    print_table(val) {
      if (val) {
        this.func_print();
      }
    },
    update_table(val) {
      if (val) {
        this.func_get_data();
      }
    },
  },
  methods: {
    iosDate(date) {
      return date ? date.replace(" ", "T") : "";
    },
    func_get_summ_type(type) {
      let result = 0;
      this.data.forEach(function (item) {
        if (
          type === 1 &&
          Number(item.type) === 2 &&
          Number(item.type_amount) === 1
        ) {
          result += Number(item.summ);
        } else if (
          type === 2 &&
          Number(item.type) === 2 &&
          Number(item.type_amount) === 2
        ) {
          result += Number(item.summ);
        } else if (
          type === 3 &&
          Number(item.type) === 1 &&
          Number(item.type_amount) === 1
        ) {
          result += Number(item.summ);
        } else if (
          type === 4 &&
          Number(item.type) === 1 &&
          Number(item.type_amount) === 2
        ) {
          result += Number(item.summ);
        }
      });
      return type === 1 || type === 2
        ? Number(result * -1).toFixed(2)
        : Number(result).toFixed(2);
    },
    func_print() {
      this.$htmlToPaper("print_table");
    },
    func_get_data() {
      this.$sendRequest("order.php/get_finance_table", {
        date_start: new Date(this.date_obj.startDate)
          .toISOString()
          .slice(0, 10),
        date_end: new Date(this.date_obj.endDate).toISOString().slice(0, 10),
      }).then((result) => {
        if (!result) return;
        this.data = result;
      });
    },
    func_view_products(obj) {
      this.order_id = Number(obj.order_id);
      this.show_modal = true;
    },
  },
  mounted() {
    this.func_get_data();
  },
  computed: {
    data_filter() {
      return [...this.data].filter((x) => Number(x.summ) !== 0);
    },
  },
};
</script>

<style>
.border_none_print {
  border: none;
}
.border_none_print td {
  border: none;
}
</style>
